import React from 'react';
import Img from 'img';
import Container from 'container';
import Headline from 'headline';
import { Link } from 'gatsby';
import s from './squares.module.scss';

// Main Component
export default function SquaresSection({ headline, subtitle, squares }) {
  return (
    <Container className={s.section}>
      <Headline white headline2 html={headline} className={s.headline} />
      <h4
        className={s.animate}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      <div
        className={`${s.squares} ${squares.length === 4 ? s.fourSquares : ' '}`}
      >
        {squares.map(({ square }, i) => (
          <div className={s.squareContainer} key={`${square.text}-${i}`}>
            <div className={s.image}>
              <Img
                src={square.image.title}
                alt={square.image.title}
                className={s.img}
              />
            </div>
            <h3>
              <Link to={square.href}>
                <span dangerouslySetInnerHTML={{ __html: square.text }} />
              </Link>
            </h3>
          </div>
        ))}
      </div>
    </Container>
  );
}
