import React from 'react';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import Button from 'button';
import validate from './validate';
import s from './form.module.scss';

export default function Form({ variation }) {
  const firstName = useFormInput('');
  const lastName = useFormInput('');
  const email = useFormInput('');
  const msg = useFormInput('');
  const phone = useFormInput('');
  const firstNameErrors = useErrors({ firstName: firstName.state }, validate);
  const lastNameErrors = useErrors({ lastName: lastName.state }, validate);
  const emailErrors = useErrors({ email: email.state }, validate);
  const msgErrors = useErrors({ msg: msg.state }, validate);
  const phoneErrors = useErrors({ phone: phone.state }, validate);
  const submitReady = useSubmitReady(
    {
      firstName: firstName.state,
      lastName: lastName.state,
      email: email.state,
      msg: msg.state,
    },
    validate
  );

  function handleSubmit() {
    if (window && window.gtag) {
      window.gtag('send', 'event', {
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: window.location.href,
        eventValue: 1
      });
    }
    
    const formBody = {
      firstName: firstName.state.value,
      lastName: lastName.state.value,
      email: email.state.value,
      msg: msg.state.value,
      phone: phone.state.value
    };
    fetch('https://us-central1-fortune-settlements.cloudfunctions.net/sendSubmissionToZapier', { headers: { 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(formBody) }).then(() => {
      window.location.href = '/thankyou/';
    }).catch(err => console.log(err));
  }

  return (
    <form className={`${s.form} ${variation && s.variation}`} autoComplete="on">
      {' '}
      {/* for variation (variation class) */}
      <div className={`${s.inputs}`}>
        <div className={s.flexed}>
          <div className={`${s.block} ${s.margin}`}>
            <label htmlFor="firstName">First name:</label>
            <p>Your first name</p>
            <input
              value={firstName.state.value}
              {...firstName.events}
              type="text"
              name="firstName"
              className={`${firstNameErrors.firstName && s.error}`}
            />
          </div>
          <div className={s.block}>
            <label htmlFor="lastName">Last name:</label>
            <p>Your last name</p>
            <input
              value={lastName.state.value}
              {...lastName.events}
              type="text"
              name="lastName"
              className={`${lastNameErrors.lastName && s.error}`}
            />
          </div>
        </div>
        <div className={s.flexed}>
          <div className={`${s.block} ${s.margin}`}>
            <label htmlFor="phone">Telephone:</label>
            <p>Where can we reach you</p>
            <input
              value={phone.state.value}
              {...phone.events}
              type="tel"
              name="phone"
              className={`${phoneErrors.phone && s.error}`}
            />
          </div>
          <div className={s.block}>
            <label htmlFor="email">E-mail:</label>
            <p>Where can we reach you</p>
            <input
              value={email.state.value}
              {...email.events}
              type="text"
              name="email"
              className={`${emailErrors.email && s.error}`}
            />
          </div>
        </div>
      </div>
      <div className={s.textArea}>
        <label htmlFor="msg">Your message:</label>
        <p>Describe your situation</p>
        <textarea
          name="msg"
          id="msg"
          cols="40"
          rows="6"
          value={msg.state.value}
          {...msg.events}
          className={`${msgErrors.msg && s.error}`}
        />
        <Button disabled={!submitReady} onClick={() => handleSubmit()}>
          Submit
        </Button>
      </div>
    </form>
  );
}
