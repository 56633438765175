import React from 'react';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Img from 'img';
import s from './splitted.module.scss';

export default function Splitted({ content_left, content_right }) {
  return (
    <div className={s.section}>
      <div className={`${s.block} ${s.colored}`}>
        <div className={s.wrapper}>
          <Headline html={content_left.title} h3 className={s.headline} white />
          {content_left.paragraphs &&
            content_left.paragraphs.map(el => {
              const Tag = getTag(el.text);
              return <Tag dangerouslySetInnerHTML={{ __html: el.text }} />;
            })}
        </div>
      </div>
      <div className={`${s.block} ${s.imaged}`}>
        <div className={s.imgWrapper}>
          <Img
            className={s.img}
            src={content_right.image.title}
            alt={content_right.image.title}
          />
        </div>
        <div className={s.overlay} />
        <div className={s.wrapper}>
          <Headline html={content_right.title} h3 className={s.headline} />
          {content_right.paragraphs &&
            content_right.paragraphs.map(el => {
              const Tag = getTag(el.text);
              return <Tag dangerouslySetInnerHTML={{ __html: el.text }} />;
            })}
        </div>
      </div>
    </div>
  );
}
