import React from 'react';
import Icon from 'icon';
import Headline from 'headline';
import Img from 'img';
import Container from 'container';
import s from './team.module.scss';

// Main Component
export default function Team({ team }) {
  return (
    <Container className={s.section}>
      {team.map(
        (
          {
            name,
            jobTitle,
            text,
            phone,
            email,
            facebook,
            twitter,
            linkedin,
            image
          },
          i
        ) => {
          const formatPhone = phoneNumberString => {
            const cleaned = phoneNumberString.replace(/\D/g, '');
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              const intlCode = match[1] ? '+1 ' : '';
              return [
                intlCode,
                '(',
                match[2],
                ') ',
                match[3],
                '-',
                match[4]
              ].join('');
            }
            return null;
          };
          const icons = { facebook, twitter, linkedin };
          const socialIcons = Object.keys({
            facebook,
            twitter,
            linkedin
          }).filter(icon => icons[icon]);
          return (
            <div className={s.teamCard}>
              <div className={s.infoContainer}>
                <div className={s.textWrapper}>
                  <Headline html={name} h2 className={s.name} />
                  <p
                    dangerouslySetInnerHTML={{ __html: name }}
                    className={s.job}
                  />
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                  <p className={s.contact}>
                    <Icon icon="phone" />{' '}
                    <a href={`tel:${phone}`} className={s.contactItem}>
                      {phone && !formatPhone(phone)
                        ? 'INVALID PHONE NUMBER'
                        : formatPhone(phone)}
                    </a>
                  </p>
                  <p className={s.contact}>
                    <Icon icon="envelope" />{' '}
                    <a href={`mailto:${email}`} className={s.contactItem}>
                      {email}
                    </a>
                  </p>
                  <div className={s.socialFlexed}>
                    {socialIcons.map(icon => (
                      <div className={s.iconHelper} key={icon}>
                        <a href={icons[icon]}>
                          <Icon icon={icon} className={s.socialIcon} />
                        </a>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
              <div className={s.imgWrapper}>
                <Img src={image.title} alt={image.title} png />
              </div>
            </div>
          );
        }
      )}
    </Container>
  );
}
