import React, { useContext } from 'react';
import { context as templatesContext } from 'shared/templates-context';
import TemplateWrapper from './template-wrapper';

export default function WordpressBody(props) {
  const { keysMode } = useContext(templatesContext) || {};
  const { acfData, componentPath, slug } = props;
  const isTemplates = slug === '/templates';
  const templateProps = (isTemplates && props.acfKeys) || null;
  const WrapperComponent = isTemplates
    ? TemplateWrapper
    : ({ children }) => <>{children}</>;
  const children = acfData
    .map(({ acf_fc_layout, component, ...childProps }, idx) => {
      const {
        acf_fc_layout: templateAcfLayout,
        component: templateComponent,
        ...templateKeysProps
      } = (templateProps && templateProps[idx]) || {};
      try {
        const Component = require(`components/templates${
          componentPath ? `/${componentPath}/` : '/'
        }${component || acf_fc_layout}`).default;
        const componentProps = isTemplates && keysMode ? templateKeysProps : childProps;
        const currentTemplateKeyProps = !!Object.keys(templateKeysProps)[0] && templateKeysProps;
        return (
          <WrapperComponent
            {...templateProps}
            title={acf_fc_layout}
            templateKeyProps={currentTemplateKeyProps}
          >
            <Component
              {...componentProps}
              templateKeysProps={currentTemplateKeyProps}
              key={`${acf_fc_layout}-${idx}`}
            />
          </WrapperComponent>
        );
      } catch (e) {
        return null;
      }
    })
    .filter(component => component);
  return <>{children}</>;
}
