import React from 'react';
import Img from 'img';
import Icon from 'icon';
import Container from 'container';
import { Link } from 'gatsby';
import Headline from 'shared/headline';
import s from './latest.module.scss';

// Main Component
export default ({ headline, posts }) => (
  <Container className={s.section}>
    <Headline>{headline}</Headline>
    <div className={s.flexed}>
      {posts.map(({ image, date, title, excerpt, link }, i) => (i < 2 ? (
        <div className={s.ltcontainer}>
          <div className={s.imgContainer}>
            <Img src={image.title} alt={image.title} />
            <div className={s.date}>{date}</div>
          </div>
          <Link to={link}>
            <h5>{title}</h5>
          </Link>
          <p>{`${excerpt} [...]`}</p>
        </div>
      ) : null))}
      <div className={s.listContainer}>
        {posts.map(({ date, title, link }, i) => (i >= 2 ? (
          <div className={s.listItem}>
            <Link to={link}>
              <h5>{title}</h5>
              <p>{date}</p>
            </Link>
          </div>
        ) : null))}
        <div className={s.listItem}>
          <Link to="/blog/">
            Blog Archive
            <Icon icon="readmorearrow" />
          </Link>
        </div>
      </div>
    </div>
  </Container>
);
