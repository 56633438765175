import React from 'react';
import Container from 'container';
import s from './splitted.module.scss';

export default function Splitted({ video_centered }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div style={{ position: 'relative', paddingTop: '48.5%' }} className={s.videoWrapper}><iframe title="home video" src="https://iframe.videodelivery.net/cd25408411186e513c3bc90f7534aac2?preload=true" style={{ border: 'none', position: 'absolute', top: '0', height: '100%', width: '100%' }} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowFullScreen="true" /></div>
      </div>
    </Container>
  );
}
