import React from 'react';
import Container from 'container';
import Headline from 'headline';

import s from './text.module.scss';

export default function TextSection({ title, text }) {
  return (
    <Container className={s.root}>
      <Headline h2 className={s.title} html={title} />
      <p className={s.text}>{text}</p>
    </Container>
  );
}
