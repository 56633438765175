import { useState, useEffect } from 'react';
import fetchWordpress from '../../api/wordpress';

export default function useWordpress(slug) {
  const [acfData, setAcfs] = useState([]);
  const callWordpress = async () => {
    const data = await fetchWordpress(`pages?slug=${slug}`);
    const newAcfs = Object.keys(data[0].acf)
      .filter(type => Array.isArray(data[0].acf[type]))
      .map(template => data[0].acf[template])
      .reduce((acc, template) => (template ? [...acc, ...template] : acc), []);
    setAcfs(newAcfs);
  };
  useEffect(() => {
    callWordpress();
  }, []);
  return acfData;
}
