import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import s from './transparent.module.scss';

// Main Component
export default function Transparent({ title, subtitle, paragraph, image, grey, green_title, ...props }) {
  const Tag = paragraph.includes('<p>') ? 'div' : 'p';
  return (
    <section className={`${s.section} ${grey ? s.grey : ' '}`}>
      <div className={s.imgWrapper}>
        <Img className={s.img} src={image.title} alt={image} />
      </div>
      <Container noSection>
        <div className={s.wrapper}>
          <Headline className={s.title} h1 center html={title} primary={!!green_title} />
          <h4
            className={s.subtitle}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <div className={s.line} />
          <Tag className={s.text} dangerouslySetInnerHTML={{ __html: paragraph }} />
        </div>
      </Container>
    </section>
  );
}
