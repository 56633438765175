import React from 'react';
import useWordpress from 'hooks/useWordpress';
import PageBody from './page-body';

export default function WordpressPage({ fetchSlug, acfData, ...props }) {
  const data = useWordpress(fetchSlug);
  return (
    <PageBody
      acfData={data}
      {...props}
    />
  );
}
