import React from 'react';
import Icon from 'icon';
import Container from 'container';
import Headline from 'shared/headline';
import { Link } from 'gatsby';
import s from './cards.module.scss';

// Main Component
export default function CardsSection({
  headline,
  cards,
  bottombar,
  link_text,
  ...props
}) {
  return (
    <Container className={s.section} grey id="cards">
      <Headline html={headline} h1 className={s.headline} />
      <div className={s.cards}>
        {cards.map(({ card }) => (
          <div className={s.cardContainer}>
            <Icon icon={card.icon} />
            <Link
              to={card.link}
              dangerouslySetInnerHTML={{ __html: card.title }}
            />
            <p dangerouslySetInnerHTML={{ __html: card.excerpt }} />
            <Link to={card.link}>
              {card.link_text} <Icon icon="readmorearrow" />
            </Link>
          </div>
        ))}
      </div>
      <div className={s.bottomBar}>
        <span dangerouslySetInnerHTML={{ __html: bottombar.text }} className={s.bottomText} />
        <span>
          <Link to={bottombar.href}>
            {bottombar.right_text}
            <Icon icon={bottombar.icon} />
          </Link>
        </span>
      </div>
    </Container>
  );
}
